import PageSEO from '../../components/shared/PageSEO';
import ErrorPage from './Error';

const ServerErrorPage = () => (
	<>
		<PageSEO pageTitle="Server Error" />
		<ErrorPage errorMessage="We're sorry, something went wrong on our end and we are working to fix it as soon as possible. Come back soon!" />
	</>
);

export default ServerErrorPage;
