import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import NavBar from './NavBar';
import useIsMobile from '../../utils/customHooks/useIsMobile';
import TopBar from './TopBar';

const Header = ({ children }) => {
	const { zIndex } = useTheme();
	const isMobile = useIsMobile();

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar
				position="sticky"
				elevation={0}
				color="inherit"
				sx={{
					// Make the header still shown when the drawer is open
					zIndex: zIndex.drawer + 1,
				}}
			>
				<TopBar />
				{!isMobile && <NavBar />}
			</AppBar>

			{/* Show the page content ensuring that it has a margin below the header */}
			<Box component="main" id="mainContent">
				{children}
			</Box>
		</Box>
	);
};

Header.propTypes = {
	children: PropTypes.object.isRequired,
};

export default Header;
