import { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import OpenInNew from '@mui/icons-material/OpenInNew';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { sendGAEvent } from '../../utils/googleAnalytics';
import { useUserContext } from '../user.context';
import useRoutesList from '../../utils/customHooks/useRoutesList';

const topBarHeightMobile = '64px';

const MobileNavMenu = () => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [menuAnchors, menuAnchorsSet] = useState({});
	const routes = useRoutesList();
	const menuItems = routes.filter(r => r.includeInHeader);
	const menuSet = (key, element) => {
		menuAnchorsSet(state => {
			const newState = { ...state };
			newState[key] = newState[key] ? null : element;
			return newState;
		});
	};
	const { userData: user = {} } = useUserContext();

	const hasUserData = Object.keys(user).length > 0;

	return (
		<>
			<IconButton
				color="inherit"
				aria-label="Mobile menu"
				aria-haspopup="true"
				onClick={() => setDrawerOpen(prev => !prev)}
			>
				{drawerOpen ? <CloseRoundedIcon /> : <MenuIcon />}
			</IconButton>
			<Drawer
				anchor="left"
				onClose={() => setDrawerOpen(false)}
				open={drawerOpen}
				role="navigation"
			>
				<List sx={{ pt: topBarHeightMobile, minWidth: '225px' }}>
					{hasUserData ? (
						menuItems
							// Only show protected menu items if the user is logged in or if they are included in the logged in header
							.filter(({ isProtected, inclueInLoggedInHeader }) =>
								user?.fitnessId
									? isProtected || inclueInLoggedInHeader
									: !isProtected
							)
							.map(
								({
									label,
									key,
									path,
									children,
									gaLabel,
									isExternalLink,
									hideChildrenFromMenu,
								}) => {
									if (children && !hideChildrenFromMenu) {
										return (
											<ListItem key={key}>
												<Stack direction="column">
													<ListItemButton
														onClick={event => menuSet(key, event.currentTarget)}
													>
														<ListItemText primary={label} />
														{menuAnchors[key] ? (
															<KeyboardArrowDownOutlinedIcon />
														) : (
															<KeyboardArrowRightIcon />
														)}
													</ListItemButton>
													<Collapse
														in={Boolean(menuAnchors[key])}
														timeout="auto"
														unmountOnExit
													>
														<List component="div" disablePadding>
															{children
																.filter(
																	({ claim }) =>
																		!claim || (user && user.claims[claim])
																)
																.map(s => (
																	<ListItemButton
																		component={Link}
																		key={s.key}
																		onClick={() => {
																			sendGAEvent(
																				`click_topNavMenu_${s.gaLabel}`,
																				{
																					login_status: user?.fitnessId
																						? 'post_login'
																						: 'pre_login',
																				}
																			);
																			setDrawerOpen(false);
																		}}
																		sx={{ pl: 4 }}
																		to={s.path}
																		{...(s.isExternalLink && {
																			target: '_blank',
																			rel: 'noreferrer',
																		})}
																	>
																		<ListItemText primary={s.label} />
																		{s.isExternalLink && (
																			<OpenInNew
																				fontSize="small"
																				sx={{ marginLeft: '.5rem' }}
																			/>
																		)}
																	</ListItemButton>
																))}
														</List>
													</Collapse>
												</Stack>
											</ListItem>
										);
									}
									return (
										<ListItem key={key}>
											<ListItemButton
												component={Link}
												onClick={() => {
													sendGAEvent(`click_topNavMenu_${gaLabel}`, {
														login_status: user?.fitnessId
															? 'post_login'
															: 'pre_login',
													});
													setDrawerOpen(false);
												}}
												to={path}
												{...(isExternalLink && {
													target: '_blank',
													rel: 'noreferrer',
												})}
											>
												<ListItemText primary={label} />
												{isExternalLink && (
													<OpenInNew
														fontSize="small"
														sx={{ marginLeft: '.5rem' }}
													/>
												)}
											</ListItemButton>
										</ListItem>
									);
								}
							)
					) : (
						<Stack spacing={2} direction="column" sx={{ px: 2, mt: 2 }}>
							<Skeleton variant="rectangular" width={150} height={30} />
							<Skeleton variant="rectangular" width={150} height={30} />
							<Skeleton variant="rectangular" width={150} height={30} />
						</Stack>
					)}
				</List>
			</Drawer>
		</>
	);
};

export default MobileNavMenu;
