import dataService from './dataService';

export const fetchFitsubscriptionManagamentData = async ({ queryKey }) => {
	const [, sessionBlob, fitnessId] = queryKey;
	const body = {
		sessionBlob,
		fitnessId,
		includeFees: true,
	};
	const response = await dataService
		.post(
			'/managesubscriptions',
			{
				...body,
			},
			{
				headers: { 'Content-Type': 'application/json' },
			}
		)
		.catch(error => error);

	return response.name === 'AxiosError' ? response : response.data;
};
