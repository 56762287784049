import PropTypes from 'prop-types';
import { alpha } from '@mui/system/colorManipulator';
import { lazy, Suspense } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { visuallyHidden } from '@mui/utils';
import { ReactComponent as ASHLogo } from '../logos/ASHLogo.svg';
import {
	pdfPath,
	languageAssistance,
	copyrightSubText,
	socialLinks,
} from './data';
import { useUserContext } from '../user.context';
import useRoutesList from '../../utils/customHooks/useRoutesList';

const ChatBot = lazy(() => import('../chatBot/ChatBot'));

const FooterMenu = () => {
	const routes = useRoutesList();
	const menuItems = routes.filter(r => r.includeInFooter);
	return (
		<Grid
			container
			direction="row"
			fontSize={'14px'}
			mt={{ xs: 2, sm: 8 }}
			spacing={3.5}
		>
			{menuItems.map(menu => (
				<Grid item xs={6} sm={6} lg={3} key={menu.path}>
					<Link
						color="inherit"
						component={Link}
						sx={{ fontSize: '1rem', fontFamily: 'Inter' }}
						href={menu.path}
						underline="hover"
					>
						{menu.label}
					</Link>
				</Grid>
			))}
		</Grid>
	);
};

const SocialMediaLink = ({ name, link, children }) => (
	<Box sx={{ width: '1.5rem', height: '1.5rem' }} mr={2}>
		<Link
			href={link}
			target="_blank"
			rel="noopener"
			data-test-ash-link-id={name}
		>
			{children}
		</Link>
	</Box>
);
SocialMediaLink.propTypes = {
	link: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	children: PropTypes.element.isRequired,
};

export default function Footer() {
	const { userData: user } = useUserContext();

	return (
		<Box
			component="footer"
			role="contentinfo"
			sx={{
				backgroundColor: 'grey.50',
				color: 'text.primary',
				p: 0,
			}}
		>
			<Box>
				<Container>
					<Grid container mb={6} justifyContent="space-between">
						<Grid item xs={12} sm={3.5}>
							<Stack>
								<Typography mt={7} mb={1} variant="overline" component="p">
									A PRODUCT OF
								</Typography>
								<Box sx={{ width: '15rem', height: '2rem' }} mb={2}>
									<Link
										href="https://www.ashcompanies.com/"
										underline="none"
										target="_blank"
										rel="noopener"
									>
										<ASHLogo title="ASH Logo" />
									</Link>
								</Box>
								<Stack direction={'row'} alignItems={'center'}>
									{socialLinks.map(social => (
										<SocialMediaLink
											link={social.link}
											key={social.name}
											name={social.name}
										>
											{social.src({
												style: {
													width: '100%',
													height: 'auto',
													maxHeight: '1.5rem',
													verticalAlign: 'middle',
												},
												title: social.alt,
											})}
										</SocialMediaLink>
									))}
								</Stack>
							</Stack>
						</Grid>
						<Grid item display={'flex'} flexWrap={'wrap'} sm={7} md={8}>
							<FooterMenu />
						</Grid>
					</Grid>
					<Divider
						sx={{ borderColor: alpha('#000000', 0.12), margin: '2.5rem 0' }}
						orientation="horizontal"
					/>
					<Typography variant="body2" mb={3}>
						<OpenInNewIcon
							sx={{ fontSize: 16, top: '0.1rem', position: 'relative' }}
						/>{' '}
						Clicking on these links will take you away from Silverandfit.com.
						Silver&amp;Fit is not responsible for any content or the privacy
						practices of external websites.
					</Typography>
					<Typography variant="body2" mt={3} mb={3}>
						The Silver&amp;Fit program is a product of American Specialty Health
						Fitness, Inc. (ASH Fitness), a subsidiary of American Specialty
						Health Incorporated. ASH Fitness complies with applicable Federal
						civil rights laws and does not discriminate on the basis of race,
						color, national origin, age, disability, or sex. Silver&amp;Fit and
						the Silver&amp;Fit logo are trademarks of ASH. Other names and logos
						may be trademarks of their respective owners.
					</Typography>
					<Grid container direction={'row'} mb={6}>
						<Grid item>
							<Typography variant="body2">
								For language services, please call the number on your member ID
								card and request an operator. For other language services:
							</Typography>
						</Grid>
						<Grid item display={'contents'}>
							{languageAssistance.map((lang, i, { length }) => (
								<Stack direction={'row'} key={lang.language}>
									<Typography variant="body2" component="div">
										<Link
											href={
												user?.footerConfig.languageAssistancePath ?? pdfPath
											}
											underline="hover"
											target="_blank"
											rel="noopener"
											color={'secondary.main'}
											paddingLeft={'4px'}
											paddingRight={'4px'}
										>
											{lang.language}
											<Box sx={visuallyHidden}>{lang.srText}</Box>
										</Link>
									</Typography>
									{length - 1 === i ? null : (
										<Divider
											orientation="vertical"
											flexItem
											sx={{ bgcolor: 'text.primary' }}
										/>
									)}
								</Stack>
							))}
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Box sx={{ backgroundColor: 'primary.main' }} pt={4} pb={4} pl={2} pr={2}>
				<Container display="flex">
					<Stack
						spacing={2}
						direction={{ xs: 'column', sm: 'row' }}
						justifyContent="space-between"
					>
						<Typography
							fontSize={'14px'}
							color={'white'}
							textAlign={{ xs: 'center' }}
							dangerouslySetInnerHTML={{ __html: user?.footerConfig.copyright }}
						/>
						<Typography
							fontSize={'14px'}
							color={'white'}
							textAlign={{ xs: 'center' }}
						>
							{copyrightSubText}
						</Typography>
					</Stack>
				</Container>
			</Box>
			<Suspense>
				<ChatBot user={user} />
			</Suspense>
		</Box>
	);
}
