const sendGAEvent = (eventName, params) => {
	if (window.gtag) {
		gtag('event', eventName, { ...params });
	} else {
		// eslint-disable-next-line no-console
		console.debug('sendingGAEvent:', eventName)
	}
};

export { sendGAEvent };
