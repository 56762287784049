import { useMemo, lazy, Suspense } from 'react';
import { useQuery } from 'urql';
import { Outlet, createRoutesFromElements, Route } from 'react-router-dom';
import ErrorNotFound from '../../pages/Errors/ErrorNotFound';
import MyAccount from '../../pages/MyAccount';
import { myAccountRoutes } from '../../pages/MyAccount/data';
import PageContainer from '../../components/shared/PageContainer/PageContainer';
import PageSkeleton, {
	SkeletonTextBlock,
	SkeletonTitle,
} from '../../components/shared/PageSkeleton';
import { useUserContext } from '../../components/user.context';
import { WbcMenuQuery } from '../../querys/wbcMenuQuery';
import redirectUnknownRoute from '../redirectUnknownRoute';
import useHomeKitRoutes from './useHomeKitsRoutes';
import HomeKitsRoute from '../../pages/HomeKits/HomeKits';
import Home from '../../pages/Home/Home';

const BillingWrapper = lazy(() =>
	import('../../pages/MyAccount/Billing/BillingWrapper')
);
const Billing = lazy(() => import('../../pages/MyAccount/Billing/Billing'));
const BillingDetail = lazy(() =>
	import('../../pages/MyAccount/Billing/BillingDetail')
);
const EditBilling = lazy(() =>
	import('../../pages/MyAccount/Billing/EditBilling')
);
const PdfViewer = lazy(() => import('../../pages/MyAccount/Billing/PdfViewer'));
const Contact = lazy(() => import('../../pages/Contact'));
const FaqRoute = lazy(() => import('../../pages/FaqRoute/FaqRoute'));

// TODO Figure out why lazy loading causes mobile header test to fail
// const HomeKitsRoute = lazy(() => import('../../pages/HomeKits/HomeKits'));
const Sitemap = lazy(() => import('../../pages/SiteMap/SiteMap'));
const WellBeingClubPublic = lazy(() =>
	import('../../pages/public/WellBeingClub')
);
const WellbeingClubRoute = lazy(() =>
	import('../../pages/WellbeingClub/WellbeingClubRoute')
);

const ExercisePlanSurvey = lazy(() =>
	import('../../pages/Surveys/ExercisePlanSurvey')
);
const WellbeingSurvey = lazy(() =>
	import('../../pages/Surveys/WellbeingSurvey')
);

const useRoutesList = () => {
	const { userData: user = {} } = useUserContext();
	const homeKitsChildRoutes = useHomeKitRoutes();

	const pauseWbcQuery = !user.fitnessId;

	const [result] = useQuery({
		query: WbcMenuQuery,
		variables: {},
		context: useMemo(
			() => ({
				url: '/api/wellbeing',
			}),
			[]
		),
		pause: pauseWbcQuery,
	});
	const { data: wbcTopicsData } = result;

	const wbcMenuItems =
		wbcTopicsData?.topicList?.topics?.map(t => {
			const label = t.name;
			const gaLabel = label.replace(/-|\s/g, '_').toLowerCase();

			return {
				element: null,
				loader: ({ request: { url } }) => redirectUnknownRoute(url),
				isProtectedPage: true,
				isWBCMenuItem: true,
				claim: 'wellBeing',
				key: `wellbeingclub${gaLabel}`,
				label,
				path: `/resourcelibrary/resources/topic/${t.id}`,

				gaLabel,
			};
		}) || [];

	const generatedRoutes = createRoutesFromElements(
		<Route path="myaccount/*" label="billing" element={<MyAccount />} id="504">
			<Route
				path="billing/*"
				label="billing"
				element={
					<Suspense fallback={<PageSkeleton />}>
						<BillingWrapper />
					</Suspense>
				}
				key="billing-wrapper"
				id="500"
			>
				<Route
					index
					id="500-1"
					key="billing"
					element={
						<Suspense fallback={<PageSkeleton />}>
							<Billing />
						</Suspense>
					}
				/>
				<Route
					path="edit-billing/*"
					id="500-3"
					key="edit-billing"
					element={
						<Suspense fallback={<PageSkeleton />}>
							<EditBilling />
						</Suspense>
					}
				/>
				{[':invoice.pdf', ':invoiceNumber/:id/:invoice.pdf'].map(
					(path, index) => (
						<Route
							path={path}
							index={path}
							key={path}
							element={
								<Suspense fallback={<PageSkeleton />}>
									<PdfViewer />
								</Suspense>
							}
							id={`501-${index}`}
						/>
					)
				)}
				<Route
					path=":invoiceNumber/:id"
					key="payment-detail"
					element={
						<Suspense fallback={<PageSkeleton />}>
							<BillingDetail />
						</Suspense>
					}
					id="500-2"
				/>
			</Route>
		</Route>
	);

	const routesList = [
		{
			claim: null,
			element: <Home />,
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			hideChildrenFromMenu: false,
			isProtected: false,
			isExternalLink: false,
			key: 'homepage',
			label: 'Homepage',
			path: '/',
			gaLabel: 'homepage',
		},
		{
			claim: null,
			element: (
				<Suspense fallback={<PageSkeleton />}>
					<WellBeingClubPublic />
				</Suspense>
			),
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			hideChildrenFromMenu: false,
			isProtected: false,
			isExternalLink: false,
			key: 'wellbeingclubpublic',
			label: 'Well-Being Club',
			gaLabel: 'well_being_club',
			path: '/well-being-club',
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			hideChildrenFromMenu: false,
			isProtected: false,
			isExternalLink: false,
			key: 'networkSearchpublic',
			label: 'Fitness Centers',
			path: '/search',
			gaLabel: 'fitness_centers',
		},
		{
			claim: null,
			element: <Outlet />,
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			hideChildrenFromMenu: false,
			isProtected: false,
			isExternalLink: false,
			key: 'fitathome',
			label: 'Fit at Home',
			path: '/',
			children: [
				{
					claim: null,
					element: null,
					loader: ({ request: { url } }) => redirectUnknownRoute(url),
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: false,
					includeInProfile: false,
					includeInSiteMapOnly: false,
					hideChildrenFromMenu: false,
					isProtected: false,
					isExternalLink: false,
					key: 'fitathomeWorkouts',
					label: 'Workouts',
					gaLabel: 'workouts',
					path: '/workouts',
					children: [
						{
							claim: null,
							element: null,
							loader: ({ request: { url } }) => redirectUnknownRoute(url),
							includeInFooter: false,
							includeInHeader: true,
							inclueInLoggedInHeader: false,
							includeInProfile: false,
							includeInSiteMapOnly: true,
							isExternalLink: false,
							isProtected: false,
							key: 'fitathomeWorkoutsInstructors',
							label: 'Intructors',
							gaLabel: 'instructors',
							path: '/workouts/instructors',
						},
					],
				},
				{
					claim: null,
					element: null,
					loader: ({ request: { url } }) => redirectUnknownRoute(url),
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: false,
					includeInProfile: false,
					includeInSiteMapOnly: false,
					hideChildrenFromMenu: false,
					isExternalLink: false,
					isProtected: false,
					key: 'fitathomeHomekits',
					label: 'Home Kits',
					gaLabel: 'home_kits',
					path: '/home-kits',
				},
			],
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			hideChildrenFromMenu: false,
			isExternalLink: false,
			isProtected: false,
			key: 'healthplans',
			label: 'Participating Health Plans',
			gaLabel: 'participating_health_plans',
			path: '/health-plans',
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			hideChildrenFromMenu: false,
			isExternalLink: false,
			isProtected: false,
			key: 'howitworks',
			label: 'How it Works',
			gaLabel: 'how_it_works',
			path: '/how-it-works',
		},
		{
			claim: null,
			element: (
				<Suspense
					fallback={
						<PageContainer>
							{/* ask if wtf can export their skeleton for faq */}
							<SkeletonTitle />
							<SkeletonTextBlock />
							<SkeletonTextBlock />
							<SkeletonTextBlock />
							<SkeletonTextBlock />
							<SkeletonTitle />
							<SkeletonTextBlock />
							<SkeletonTextBlock />
							<SkeletonTextBlock />
							<SkeletonTextBlock />
						</PageContainer>
					}
				>
					<FaqRoute />
				</Suspense>
			),
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			hideChildrenFromMenu: false,
			isExternalLink: false,
			isProtected: false,
			key: 'faq',
			label: 'FAQ',
			gaLabel: 'faq',
			path: '/faq',
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			hideChildrenFromMenu: false,
			isExternalLink: false,
			isProtected: false,
			key: 'blog',
			label: 'Blog',
			gaLabel: 'blog',
			path: 'https://blog.silverandfit.com/',
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: true,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			hideChildrenFromMenu: false,
			isProtected: true,
			isExternalLink: false,
			key: 'dashboard',
			label: 'Dashboard',
			gaLabel: 'dashboard',
			path: '/dashboard',
		},
		{
			claim: 'wellBeing',
			element: <Outlet />,
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: true,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			hideChildrenFromMenu: false,
			isProtected: true,
			key: 'wellbeingclub',
			label: 'Well-Being Club',
			path: '/',
			children: [
				{
					claim: 'wellBeing',
					element: (
						<Suspense fallback={<PageSkeleton />}>
							<WellbeingClubRoute />
						</Suspense>
					),
					isExternalLink: false,
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					includeInSiteMapOnly: false,
					hideChildrenFromMenu: false,
					isProtected: true,
					key: 'wellbeingclubLanding',
					label: 'Well-Being Club',
					gaLabel: 'well_being_club',
					path: '/wellbeing',
				},
				...wbcMenuItems,
				{
					claim: 'socialClubs',
					element: null,
					loader: ({ request: { url } }) => redirectUnknownRoute(url),
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					includeInSiteMapOnly: false,
					hideChildrenFromMenu: false,
					isProtected: true,
					isExternalLink: false,
					key: 'wellbeingclubSocialClubs',
					label: 'Social Groups',
					gaLabel: 'social_groups',
					path: '/social-clubs',
				},

				{
					claim: 'canAttendVirtualEvents',
					element: null,
					loader: ({ request: { url } }) => redirectUnknownRoute(url),
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					includeInSiteMapOnly: false,
					hideChildrenFromMenu: false,
					isProtected: true,
					isExternalLink: true,
					key: 'wellbeingclubVirtualEvent',
					label: 'Virtual Events',
					gaLabel: 'virtual_events',
					path: 'https://events.zoom.us/eo/Ai9FFq9XTSM0_Ypv4fVeLQuYAT7dNkC4x5VaadU-QkrZqTPOVXOg~AggLXsr32QYFjq8BlYLZ5I06Dg',
					newTab: true,
				},
				{
					claim: 'newsletters',
					element: null,
					loader: ({ request: { url } }) => redirectUnknownRoute(url),
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					includeInSiteMapOnly: false,
					hideChildrenFromMenu: false,
					isProtected: true,
					isExternalLink: false,
					key: 'wellbeingclubNewsletters',
					label: 'Newsletters',
					gaLabel: 'newsletters',
					path: '/newsletters',
				},
			],
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: true,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			hideChildrenFromMenu: false,
			isProtected: true,
			isExternalLink: false,
			key: 'networkSearch',
			label: 'Fitness Centers',
			path: '/search',
			gaLabel: 'fitness_centers',
		},

		{
			claim: 'digitalWorkouts',
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: true,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			hideChildrenFromMenu: false,
			isProtected: true,
			isExternalLink: false,
			key: 'digitalWorkouts',
			label: 'Workouts',
			gaLabel: 'workouts',
			path: '/ResourceLibrary/workouts/all',
		},
		{
			claim: 'atHomeMembership',
			element: <HomeKitsRoute />,
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: true,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			hideChildrenFromMenu: true,
			isProtected: true,
			isExternalLink: false,
			key: 'homeKits',
			label: 'Home Kits',
			gaLabel: 'home_kits',
			path: '/homekit',
			children: homeKitsChildRoutes,
		},
		{
			claim: 'socialEvents',
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: true,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			hideChildrenFromMenu: false,
			isProtected: true,
			isExternalLink: false,
			key: 'socialEvents',
			label: 'Social Events',
			gaLabel: 'social_events',
			path: '/social-events',
		},
		{
			claim: 'connectedV2',
			element: <Outlet />,
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: true,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			hideChildrenFromMenu: false,
			isProtected: true,
			key: 'connected',
			label: 'Connected!',
			path: '/',
			gaLabel: 'connected',
			children: [
				{
					claim: 'connectedV2',
					element: null,
					loader: ({ request: { url } }) => redirectUnknownRoute(url),
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					includeInSiteMapOnly: false,
					hideChildrenFromMenu: false,
					isProtected: true,
					key: 'connecteddashboard',
					label: 'Dashboard',
					path: '/Connected/ActivityTracker',
					gaLabel: 'connected_dashboard',
				},
				{
					claim: 'connectedV2',
					element: null,
					loader: ({ request: { url } }) => redirectUnknownRoute(url),
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					includeInSiteMapOnly: false,
					hideChildrenFromMenu: false,
					isProtected: true,
					key: 'connectedfitnesscentervisits',
					label: 'Fitness Center Visits',
					path: '/Connected/CheckIns',
					gaLabel: 'connected_fitness_center_visits',
				},
				{
					claim: 'connectedV2',
					element: null,
					loader: ({ request: { url } }) => redirectUnknownRoute(url),
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					includeInSiteMapOnly: false,
					hideChildrenFromMenu: false,
					isProtected: true,
					key: 'connectedappsanddevices',
					label: 'Apps and Devices',
					path: '/Connected/Devices',
					gaLabel: 'connected_apps_and_devices',
				},
			],
		},
		{
			claim: 'incentivesPlan',
			element: <Outlet />,
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: true,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			hideChildrenFromMenu: false,
			isProtected: true,
			key: 'rewards',
			label: 'Rewards',
			gaLabel: '',
			path: '/',
			children: [
				{
					claim: 'incentivesPlan',
					element: null,
					loader: ({ request: { url } }) => redirectUnknownRoute(url),
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					includeInSiteMapOnly: false,
					isProtected: true,
					isExternalLink: false,
					key: 'rewardsFitnessActivity',
					label: 'Points',
					gaLabel: 'points',
					path: '/Incentives/RewardsProgram/',
				},
				{
					claim: 'incentivesPlan',
					element: null,
					loader: ({ request: { url } }) => redirectUnknownRoute(url),
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					includeInSiteMapOnly: false,
					isExternalLink: false,
					isProtected: true,
					key: 'rewardsRewards',
					label: 'My Rewards',
					gaLabel: 'my_rewards',
					path: '/Incentives/Rewards',
				},
			],
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: true,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			isProtected: false,
			key: 'aboutus',
			label: 'About Us',
			path: '/About',
			gaLabel: 'about_us',
		},

		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: true,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			isProtected: false,
			key: 'faq',
			label: 'FAQ',
			path: '/faq',
			gaLabel: 'faq',
		},

		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: true,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			isProtected: false,
			key: 'privacy',
			label: 'Privacy Statement',
			path: '/privacy',
			gaLabel: 'privacy_statement',
		},
		{
			claim: null,
			element: null,
			includeInFooter: true,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			isProtected: false,
			key: 'sitemap',
			label: 'Site Map',
			path: '/sitemap',
			gaLabel: 'sitemap',
		},
		{
			claim: null,
			element: (
				<Suspense fallback={<PageSkeleton />}>
					<Contact />
				</Suspense>
			),
			includeInFooter: true,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			isProtected: false,
			key: 'contactus',
			label: 'Contact Us',
			path: '/contactus',
			gaLabel: 'contact_us',
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: true,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			isProtected: false,
			key: 'terms',
			label: 'Terms & Conditions',
			path: '/TermsAndConditions',
			gaLabel: 'terms_and_conditions',
		},
		{
			claim: null,
			element: <MyAccount />,
			includeInFooter: false,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			isProtected: true,
			key: 'myaccount',
			label: null,
			path: '/MyAccount',
			gaLabel: 'myaccount',
			children: [...myAccountRoutes],
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: true,
			isProtected: false,
			key: 'identity-login',
			label: 'Login',
			path: '/identity/login',
			gaLabel: 'login',
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: true,
			isProtected: false,
			key: 'identity-forgotuser',
			label: 'Forgot Username',
			path: '/identity/forgotUsername',
			gaLabel: 'forgotuser',
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: true,
			isProtected: false,
			key: 'identity-forgotpassword',
			label: 'Forgot Password',
			path: '/identity/forgotPassword',
			gaLabel: 'forgotpass',
		},

		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: true,
			isProtected: false,
			key: 'registration',
			label: 'Registration',
			path: '/identity/registration',
			gaLabel: 'registration',
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: true,
			isProtected: true,
			key: 'subscription-payment-empty',
			label: 'Cart',
			path: '/subscription',
			gaLabel: 'cart',
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			isProtected: true,
			key: 'logout',
			label: 'Log Out',
			path: '/logout',
			gaLabel: 'log_out',
		},
		{
			claim: 'wellBeing',
			element: (
				<Suspense fallback={<PageSkeleton />}>
					<WellbeingSurvey />
				</Suspense>
			),
			includeInFooter: false,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: true,
			isProtected: true,
			key: 'wbsurvey',
			label: 'Well-Being Survey',
			path: '/survey/wellbeing',
			gaLabel: '',
		},
		{
			claim: null,
			element: (
				<Suspense fallback={<PageSkeleton />}>
					<ExercisePlanSurvey />
				</Suspense>
			),
			includeInFooter: false,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: true,
			isProtected: true,
			key: 'epsurvey',
			label: 'Workout Plan Survey',
			path: '/survey',
			gaLabel: '',
		},
		{
			claim: null,
			element: <ErrorNotFound />,
			includeInFooter: false,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			includeInSiteMapOnly: false,
			isProtected: false,
			key: 'notFound',
			label: null,
			path: '*',
			gaLabel: '',
		},
		...generatedRoutes,
	];
	/**
	 * Adding sitemap component to the sitemap route after the routesList is defined
	 * Had to pass in the routes const as a prop instead of importing directly in the sitemap component
	 * When importing into the component it created a circular dependency issue
	 * more info on the issue here https://medium.com/content-uneditable/circular-dependencies-in-javascript-a-k-a-coding-is-not-a-rock-paper-scissors-game-9c2a9eccd4bc
	 */
	const routes = routesList.map(r => {
		if (r.key === 'sitemap') {
			r.element = (
				<Suspense fallback={<PageSkeleton />}>
					<Sitemap routes={routesList} secondaryRoutes={myAccountRoutes} />
				</Suspense>
			);
		}
		return r;
	});

	return routes;
};

export default useRoutesList;
