import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { createClient, Provider } from 'urql';
import { QueryClient, QueryClientProvider } from 'react-query';

import EveThemeProvider from '@ashn/eve-themes-react/EveThemeProvider';
import reportWebVitals from './reportWebVitals';
import { UserContextProvider } from './components/user.context';
import App from './App';

// Create the urql client

const client = createClient({
	url: '/graphql',
});

const normalOptions = {
	defaultOptions: {
		queries: {
			suspense: true,
			refetchOnWindowFocus: false,
		},
	},
};
const unitTestOptions = {
	defaultOptions: {
		queries: {
			...normalOptions.defaultOptions.queries,
			retry: false,
		},
	},
};
const queryClientOptions =
	process.env.NODE_ENV === 'test' ? unitTestOptions : normalOptions;

const queryClient = new QueryClient(queryClientOptions);

if (
	process.env.NODE_ENV === 'development' &&
	!process.env.REACT_APP_PLAYWRIGHT
) {
	// eslint-disable-next-line global-require
	const { worker } = require('./mocks/browser');
	worker.start();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<Provider value={client}>
				<EveThemeProvider themeKey="sf" themeMode="light">
					<UserContextProvider>
						<HelmetProvider>
							<App />
						</HelmetProvider>
					</UserContextProvider>
				</EveThemeProvider>
			</Provider>
		</QueryClientProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
