import { gql } from 'urql';

export const WbcMenuQuery = gql`
	query topics {
		topicList {
			topics {
				id
				name
			}
		}
	}
`;
