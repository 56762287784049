import PropTypes from 'prop-types';
import { lazy, Suspense, useState, Fragment } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTheme, lighten } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
// Material Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import CorporateFareIcon from '@mui/icons-material/CorporateFareOutlined';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SearchIcon from '@mui/icons-material/Search';
// Custom Icon
import { ReactComponent as FCMap } from './images/fitnessCenters.svg';
// Share Components
import HealthPlansSection from '../../components/shared/HealthPlansSection';
import HeroSectionCentered from '../../components/shared/layout/HeroSectionCentered';
import IconCard from '../../components/shared/IconCard';
import PageContainer from '../../components/shared/PageContainer/PageContainer';
import PageSEO from '../../components/shared/PageSEO';
import {
	PictureHelper,
	BoxBackground,
} from '../../components/imageHelpers/ImageHelpers';

import { useUserContext } from '../../components/user.context';
import useIsMobile from '../../utils/customHooks/useIsMobile';
import { sendGAEvent } from '../../utils/googleAnalytics';
import useScrollPosition from '../../utils/customHooks/useScrollPosition';

// Assets
import { features, fitAtHomeBullets, steps } from './data';
import {
	fitnessCoachBg,
	wellbeingCoachBg,
	wellbeingBg,
	heroBg,
	testimonialBg,
	getFitAtHomeBg,
} from './imageLists';

const LazyLocationSearch = lazy(() => import('./temporary/LocationSearch'));

const HeroContent = ({ isMd, navigate }) => (
	<Stack spacing={4} alignItems={{ sm: 'center', md: 'flex-start' }}>
		<Typography variant="h2" component="h1" color="primary">
			Enjoy the freedom of a flexible fitness program.
		</Typography>
		<Typography>
			With flexible options to support any healthy aging journey, you can
			exercise at a fitness center, join classes and events from home, and
			access personalized resources to enhance your well-being. Available
			through participating Medicare plans.
		</Typography>

		<Button
			component={NavLink}
			variant="contained"
			data-testid="hero-elig"
			id="stickyElig"
			size="large"
			onClick={() => {
				sendGAEvent('click_registration');
				navigate('/identity/registration');
			}}
		>
			Check Eligibility
		</Button>

		{!isMd && (
			<Stack width="100%">
				<PictureHelper alt="" images={heroBg} style={{ width: '100%' }} />
			</Stack>
		)}
	</Stack>
);

HeroContent.propTypes = {
	isMd: PropTypes.bool,
	navigate: PropTypes.func.isRequired,
};
HeroContent.defaultProps = {
	isMd: true,
};

const Home = () => {
	const [videoOpen, videoOpenSet] = useState(false);
	const [warningOpen, setWarningOpen] = useState(true);
	const { userData: user = {} } = useUserContext();
	const theme = useTheme();
	const isMobile = useIsMobile();
	const isSm = useMediaQuery(theme.breakpoints.up('sm'));
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const navigate = useNavigate();
	const scrollYPosition = useScrollPosition();
	const siteWarning = user?.siteWarning;

	const headerOffset =
		document.getElementsByTagName('header')[0]?.offsetHeight ?? 0;
	const siteWarningOffset =
		document.getElementById('siteWarning')?.offsetHeight ?? 0;

	const stickyButton = () => {
		const heroBtnOffset = document.getElementById('stickyElig')?.offsetTop ?? 0;
		const heroBtnHeight =
			document.getElementById('stickyElig')?.offsetHeight ?? 0;

		return scrollYPosition > heroBtnOffset + heroBtnHeight - headerOffset;
	};

	const itemCounts = {
		premiumFacilitiesCount:
			Number(user?.hostProperties?.premiumFacilitiesCount) || 5,
		standardFacilitiesCount:
			Number(user?.hostProperties?.standardFacilitiesCount) || 0,
	};
	const totalCount = (
		itemCounts.premiumFacilitiesCount + itemCounts.standardFacilitiesCount
	).toLocaleString();

	// TODO remove after 1/1/2024
	const disable2024Content =
		// eslint-disable-next-line
		user?.hostProperties?.disable2024ContentEnabled == 'true';

	return (
		<Box pt={warningOpen ? `${siteWarningOffset}px` : ''}>
			{/* site warning messag */}
			{siteWarning && (
				<Collapse
					in={warningOpen}
					sx={{
						position: 'fixed',
						top: headerOffset,
						width: '100%',
						zIndex: 600,
						// matching alert background color to make the banner go to the edge of the page
						bgcolor: lighten(theme.palette.info.light, 0.9),
					}}
				>
					<Container>
						<Alert
							id="siteWarning"
							data-testid="sitewarning"
							severity="info"
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => {
										setWarningOpen(false);
									}}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}
						>
							<Typography dangerouslySetInnerHTML={{ __html: siteWarning }} />
						</Alert>
					</Container>
				</Collapse>
			)}
			<PageSEO pageTitle="Homepage" />
			{/* stick check elig */}
			{isMobile && stickyButton() && (
				<Fab
					variant="extended"
					color="secondary"
					data-testid="floating-elig"
					onClick={() => {
						sendGAEvent('click_registration');
						navigate('/identity/registration');
					}}
					sx={{
						position: 'fixed',
						bottom: 16,
						right: '50%',
						boxShadow: 2,
						transform: 'translateX(50%)',
					}}
				>
					Check Eligibility
				</Fab>
			)}
			{/* desktop hero */}
			{isMd && (
				<BoxBackground
					images={heroBg}
					sx={{
						backgroundSize: 'cover',
						width: '100%',
						minHeight: '100%',
						backgroundPosition: 'top right 18%',
					}}
				>
					<Container>
						<Grid
							container
							py={{ xs: 4, md: 20 }}
							alignItems="center"
							justifyContent={{ xs: 'center', md: 'initial' }}
						>
							<Grid item xs={12} md={8}>
								<HeroContent isMd={isMd} navigate={navigate} />
							</Grid>
						</Grid>
					</Container>
				</BoxBackground>
			)}

			<PageContainer>
				{/* mobile hero */}
				{!isMd && (
					<Grid
						container
						alignItems="center"
						justifyContent="center"
						rowGap={4}
					>
						<Grid item xs={12} textAlign={{ xs: 'center' }}>
							<HeroContent isMd={isMd} navigate={navigate} />
						</Grid>
					</Grid>
				)}
				{/* wellbeing club */}
				<Grid
					container
					py={4}
					rowGap={4}
					alignItems="center"
					justifyContent={{ xs: 'center', lg: 'space-between' }}
				>
					<Grid item xs={12} md={5}>
						<Stack spacing={4} sx={{ alignItems: { md: 'flex-start' } }}>
							<Typography
								variant="h3"
								component="h2"
								fontWeight={600}
								textAlign={{ xs: 'center', md: 'left' }}
							>
								Well-Being Club
							</Typography>
							<Typography textAlign={{ xs: 'center', md: 'left' }}>
								A virtual space for member enrichment, connection, and community
								building with Healthy Aging resources, live-streaming virtual
								events and in-person meetups.
							</Typography>
							{!isMd && (
								<Stack width="100%">
									<PictureHelper
										alt=""
										images={wellbeingBg}
										style={{ width: '100%' }}
									/>
								</Stack>
							)}
							<Stack alignItems={{ sm: 'center', md: 'left' }}>
								<Button
									component={NavLink}
									variant="contained"
									size="large"
									to="/well-being-club"
									xs={{ mb: 2 }}
								>
									Learn More
								</Button>
							</Stack>
						</Stack>
					</Grid>
					<Grid item md={1} />
					{isMd && (
						<Grid item xs={12} md={5} display="flex" justifyContent="center">
							<PictureHelper
								alt=""
								images={wellbeingBg}
								style={{ width: '100%' }}
							/>
						</Grid>
					)}
				</Grid>
				{/* Fitness Center Search */}
				<HeroSectionCentered title="Find Your Fitness Center">
					<Grid
						container
						justifyContent="center"
						textAlign="center"
						rowSpacing={{ xs: 0, md: 5 }}
					>
						<Grid item xs={12} md={8}>
							<Typography>
								As a member, you can choose from our network of {totalCount}+
								Standard and Premium fitness center memberships to find the
								workout that&apos;s right for you.{' '}
								<Link
									color="secondary"
									component={NavLink}
									to="/identity/login"
								>
									Log in
								</Link>{' '}
								or{' '}
								<Link
									color="secondary"
									component={NavLink}
									onClick={() => {
										sendGAEvent('click_registration');
									}}
									to="identity/registration"
								>
									check your eligibility
								</Link>{' '}
								to see which of these features and participating fitness centers
								are available to you.
							</Typography>
						</Grid>
						<Grid item xs={12} md={8} my={4}>
							<FormControl
								variant="outlined"
								sx={{
									backgroundColor: 'primary.contrastText',
									borderRadius: '4px',
									width: '100%',
								}}
							>
								<Suspense
									fallback={<Skeleton variant="rectangular" height={20} />}
								>
									<OutlinedInput
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="search fitness centers"
													edge="end"
													color="secondary"
												>
													<SearchIcon />
												</IconButton>
											</InputAdornment>
										}
										id="network-search-input"
										data-testid="network-search"
										inputComponent={LazyLocationSearch}
										placeholder="City, State, or ZIP Code"
										sx={{
											'& div:nth-of-type(2)': { width: '100%' },
										}}
									/>
								</Suspense>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={8} my={4}>
							<FCMap />
						</Grid>
						<Grid item xs={12} md={8}>
							<Typography variant="caption">
								Please note that fitness centers, amenities, and classes vary by
								location, benefit plan, and availability. Any non-standard
								fitness center services that typically require an additional fee
								are not included. Not all locations will be offered by all
								health plans. Fees and Premium access vary by health plan and
								location. Fitness locations are subject to price change. Terms
								and conditions apply.
							</Typography>
						</Grid>
					</Grid>
				</HeroSectionCentered>
				{/* Fit At Home */}
				<Grid
					container
					py={{ xs: 2, md: 8 }}
					my={{ xs: 2, md: 4 }}
					px={{ xs: 0, md: 0 }}
					columnGap={{ md: 10 }}
					rowGap={{ xs: 2, md: 4 }}
					justifyContent={{ xs: 'center', lg: 'initial' }}
				>
					{isMd && (
						<Grid item xs={10} sm={7} md={4} lg={5}>
							<PictureHelper
								alt=""
								images={getFitAtHomeBg}
								style={{ width: '100%' }}
							/>
						</Grid>
					)}

					<Grid item xs={12} md={6} lg={5}>
						<Box mb={6}>
							<Typography
								variant="h3"
								component="h1"
								fontWeight={400}
								textAlign={{ xs: 'center', md: 'left' }}
							>
								Get{' '}
								<strong style={{ fontWeight: 600 }}>Fit at Home&trade;</strong>
							</Typography>
						</Box>
						<Grid
							container
							alignItems="center"
							display="flex"
							rowGap={2}
							columnGap={2}
						>
							{fitAtHomeBullets.map(({ header, content }) => (
								<Fragment key={header}>
									<Grid item xs={1} key={header}>
										<ArrowForwardIcon color="tertiary" />
									</Grid>
									<Grid item xs={10}>
										<Typography variant="h5" component="h2" fontWeight={600}>
											{header}
										</Typography>
									</Grid>
									<Grid item xs={1} />
									<Grid item xs={10}>
										<Typography>{content()}</Typography>
									</Grid>
									<Grid item xs={12} />
								</Fragment>
							))}
							<Grid item md={1} />
							<Grid item xs={12} md={10}>
								<Stack alignItems={{ sm: 'center', md: 'flex-start' }}>
									<Button
										component={NavLink}
										variant="contained"
										size="large"
										to="/workouts"
										xs={{ mb: 2 }}
									>
										Learn More
									</Button>
								</Stack>
							</Grid>
							<Grid item xs={12} />
						</Grid>
					</Grid>
				</Grid>
				{/* TODO remove after 1/1/2024 -check for toggle */}
				{/* Fitness Coach */}
				{!disable2024Content && (
					<Grid
						data-testid="fitnesscoach"
						container
						pt={4}
						py={4}
						rowGap={4}
						alignItems="center"
						justifyContent={{
							xs: 'center',
							sm: 'space-between',
							md: 'initial',
						}}
					>
						<Grid item md={1} />
						<Grid item xs={12} sm={5} md={4}>
							<Stack spacing={4} sx={{ alignItems: { sm: 'flex-start' } }}>
								<Typography
									variant="h3"
									component="h2"
									fontWeight={600}
									textAlign={{ xs: 'center', sm: 'left' }}
								>
									FitnessCoach&reg; Virtual Training
								</Typography>
								<Typography textAlign={{ xs: 'center', sm: 'left' }}>
									Members can meet with a certified personal fitness trainer via
									live video sessions to customize workout plans, establish
									exercise routines, and check-in on progress.
								</Typography>
								{!isSm && (
									<Stack alignItems={{ xs: 'center' }}>
										<PictureHelper
											alt=""
											images={fitnessCoachBg}
											style={{ width: { xs: '100%', sm: 'auto' } }}
										/>
									</Stack>
								)}
								<Button
									component={NavLink}
									variant="contained"
									size="large"
									onClick={() => {
										sendGAEvent('click_registration');
										navigate('/identity/registration');
									}}
								>
									Check Eligibility
								</Button>
							</Stack>
						</Grid>
						<Grid item sm={1} />
						{isSm && (
							<Grid item sm={5}>
								<PictureHelper
									alt=""
									images={fitnessCoachBg}
									style={{ width: '100%' }}
								/>
							</Grid>
						)}
					</Grid>
				)}
				{/* Wellbeing Coaching */}
				<Grid
					container
					pt={4}
					pb={{ md: 4 }}
					rowGap={4}
					alignItems={{ xs: 'center' }}
					justifyContent={{ xs: 'center', sm: 'space-between', md: 'revert' }}
					// TODO remove after 1/1/2024
					flexDirection={{
						md: disable2024Content ? 'row-reverse' : 'row',
					}}
				>
					{isSm && (
						<>
							<Grid item xs={12} sm={6}>
								<PictureHelper
									alt=""
									images={wellbeingCoachBg}
									style={{ width: '100%' }}
								/>
							</Grid>
							<Grid item sm={1} />
						</>
					)}
					<Grid item xs={12} sm={5}>
						<Stack
							spacing={4}
							sx={{
								alignItems: { sm: 'flex-start' },
								justifyContent: 'flex-start',
							}}
						>
							<Typography
								variant="h3"
								component="h2"
								fontWeight={600}
								textAlign={{ xs: 'center', sm: 'left' }}
							>
								Well-Being Coaching
							</Typography>
							<Typography textAlign={{ xs: 'center', sm: 'left' }}>
								Members can get paired up with a Well-Being Coach to help work
								on their fitness, nutrition, and lifestyle goals during
								scheduled phone or video sessions.
							</Typography>
							{!isSm && (
								<PictureHelper
									alt=""
									images={wellbeingCoachBg}
									style={{ width: '100%' }}
								/>
							)}
							<Button
								component={NavLink}
								variant="contained"
								size="large"
								sx={{ marginTop: 'auto' }}
								onClick={() => {
									sendGAEvent('click_registration');
									navigate('/identity/registration');
								}}
							>
								Check Eligibility
							</Button>
						</Stack>
					</Grid>
				</Grid>
				{/* Features */}
				<Box my={{ xs: 2, md: 9 }}>
					<Grid container justifyContent="center" my={5}>
						<Grid item xs={1}>
							<Divider sx={{ borderColor: 'tertiary.main' }} />
						</Grid>
					</Grid>
					<Grid container alignItems="center" flexDirection="column" rowGap={5}>
						<Grid item xs={12} md={8}>
							<Typography variant="h3" component="h2" textAlign="center">
								A Complete Program That&apos;s Safe and Convenient for Members
							</Typography>
						</Grid>
						<Grid item xs={12} md={8} lg={6}>
							<Typography textAlign="center">
								Not all health plans offer each feature.
							</Typography>
						</Grid>
					</Grid>

					<Grid
						container
						columnSpacing={5}
						rowSpacing={{ xs: 4, lg: 9 }}
						py={{ xs: 5, lg: 10 }}
						alignItems="stretch"
						justifyContent="center"
						display="flex"
					>
						{features({
							totalCount,
							disable2024Content:
								Object.keys(user?.hostProperties ?? {}).length > 0 &&
								disable2024Content,
						}).map(({ title, icon, content }) => (
							<Grid item xs={12} sm={6} lg={4} display="flex" key={title}>
								<IconCard title={title} icon={icon}>
									{content()}
								</IconCard>
							</Grid>
						))}
					</Grid>
					<Stack alignItems={{ sm: 'center' }}>
						<Button
							component={NavLink}
							variant="contained"
							size="large"
							onClick={() => {
								sendGAEvent('click_registration');
								navigate('/identity/registration');
							}}
						>
							Check Eligibility
						</Button>
					</Stack>
				</Box>
				<HealthPlansSection disableMargin />
				{/* Steps */}
				<HeroSectionCentered
					title="Ready to Begin?"
					hasBackground
					disableMargin
				>
					<Grid
						container
						columnGap={8}
						my={{ xs: 2, md: 5 }}
						textAlign="center"
						justifyContent="center"
						rowGap={4}
					>
						{steps.map(({ header, body }, index) => (
							<Grid
								item
								xs={12}
								md={3}
								alignItems="center"
								display="flex"
								flexDirection="column"
								key={header}
							>
								<Avatar
									sx={{
										bgcolor: 'tertiary.main',
										mb: 3,
										width: 62,
										height: 62,
									}}
								>
									<Typography fontSize={36} fontWeight={600}>
										{index + 1}
									</Typography>
								</Avatar>
								<Typography
									variant="h5"
									component="h3"
									fontWeight={600}
									gutterBottom
								>
									{header}
								</Typography>
								<Typography>{body}</Typography>
							</Grid>
						))}
					</Grid>
				</HeroSectionCentered>
				{/* Testimonial Video Image */}
				<Box sx={{ position: 'relative' }}>
					<PictureHelper
						alt=""
						images={testimonialBg}
						style={{ width: '100%' }}
					/>
					<IconButton
						color="info"
						onClick={() => videoOpenSet(true)}
						sx={{
							position: 'absolute',
							left: '50%',

							top: '50%',
							transform: 'translate(-50%, -50%)',
						}}
						aria-label="Play Video"
					>
						<Avatar sx={{ bgcolor: '#EAF4F5', width: '80px', height: ' 80px' }}>
							<PlayArrowIcon color="primary" sx={{ fontSize: '3rem' }} />
						</Avatar>
					</IconButton>

					{/* Modal for the video */}
					<Dialog
						fullWidth
						maxWidth="lg"
						open={videoOpen}
						onClose={() => videoOpenSet(false)}
						aria-labelledby="video-modal-title"
					>
						<DialogTitle id="video-modal-title">
							Silver&amp;Fit Product Overview
						</DialogTitle>
						<DialogContent>
							<div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
								<iframe
									className="videoFrame"
									src="https://www.youtube.com/embed/fjq8HXzeCnE?modestbranding=1&amp;rel=0"
									title="Find out more about Silver and Fit"
									style={{
										position: 'absolute',
										top: 0,
										left: 0,
										width: '100%',
										height: '100%',
									}}
								/>
							</div>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => videoOpenSet(false)} autoFocus>
								CLOSE
							</Button>
						</DialogActions>
					</Dialog>
				</Box>
				{/* testimonials */}
				<HeroSectionCentered title="What Members Are Saying">
					<Grid container width="100%" justifyContent="center" rowGap={4}>
						<Grid
							item
							bgcolor="background.secondary"
							p={4}
							xs={12}
							md={4}
							lg={3}
							mr={{ sm: 2 }}
						>
							<Typography>
								&ldquo;We joined the Silver&Fit program because there are so
								many things offered, and it will help keep us young and healthy.
								We&apos;re silver, we&apos;re fit, and we&apos;re
								fabulous!&rdquo; - Member
							</Typography>
						</Grid>
						<Grid
							item
							bgcolor="background.secondary"
							p={4}
							xs={12}
							md={4}
							lg={3}
							mr={{ sm: 2 }}
						>
							<Typography>
								&ldquo;I&apos;m so happy because everything that I&apos;m
								learning here, I carry with me wherever I go and in whatever I
								do.&rdquo; - Member
							</Typography>
						</Grid>
						<Grid
							item
							bgcolor="background.secondary"
							p={4}
							xs={12}
							md={4}
							lg={3}
						>
							<Typography>
								&ldquo;At our age, this is quality of life. In order to enjoy
								it, you&apos;ve got to be in shape.&rdquo; - Member
							</Typography>
						</Grid>
					</Grid>
				</HeroSectionCentered>
				{/* Healthplans/fitness center */}
				<Box my={{ md: 9 }}>
					<Grid container justifyContent="center" my={5}>
						<Grid item xs={1}>
							<Divider sx={{ borderColor: 'tertiary.main' }} />
						</Grid>
					</Grid>
					<Grid container alignItems="center" flexDirection="column" rowGap={5}>
						<Grid item xs={12} md={8}>
							<Typography variant="h3" component="h2" textAlign="center">
								Are you a health plan or a fitness center?
							</Typography>
						</Grid>
					</Grid>

					<Grid
						container
						columnGap={5}
						rowSpacing={{ xs: 4, lg: 9 }}
						py={{ xs: 4, lg: 10 }}
						justifyContent="center"
					>
						<Grid item xs={12} sm={5} lg={4} display="flex">
							<IconCard title="Health Plans" icon={<HealthAndSafetyIcon />}>
								<Typography>
									We offer health plans flexible program designs to fit any
									budget. Attract new members, make an impact on their health,
									and improve your bottom line.{' '}
									<Link
										color="secondary"
										component={NavLink}
										to="/health-plans"
									>
										See health plans offering the Silver&Fit program.
									</Link>
								</Typography>
							</IconCard>
						</Grid>
						<Grid item xs={12} sm={5} lg={4} display="flex">
							<IconCard title="Fitness Centers" icon={<CorporateFareIcon />}>
								<Typography>
									Interested in becoming part of our network? Help make physical
									fitness easier for older adults and see how you can benefit
									from joining.{' '}
									<Link
										color="secondary"
										component={NavLink}
										to="https://www.ashlink.com/ASH/public/Providers/Network/joinfitness.aspx"
									>
										Learn more
									</Link>
								</Typography>
							</IconCard>
						</Grid>
					</Grid>
				</Box>
				{/* Faq */}
				<HeroSectionCentered title="Have More Questions?" hasBackground>
					<Stack alignItems="center">
						<Button
							variant="outlined"
							component={NavLink}
							size="large"
							to="/faq"
						>
							Read Our FAQ
						</Button>
					</Stack>
				</HeroSectionCentered>
			</PageContainer>
		</Box>
	);
};

export default Home;
