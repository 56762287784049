import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const HeroSectionCentered = ({
	title,
	subTitle,
	children,
	hasBackground,
	disableMargin,
}) => (
	<Box
		sx={{
			backgroundColor: hasBackground ? 'background.secondary' : '',
			py: { xs: 4, sm: 10 },
			mb: disableMargin ? 0 : 4,
		}}
	>
		<Grid container alignItems="center" justifyContent="center" spacing={2}>
			<Grid item xs={11} sm={10} md={8} lg={6}>
				<Stack
					spacing={2}
					alignItems={{ sm: 'center' }}
					sx={{ textAlign: 'center', mx: 2 }}
				>
					<Typography variant="h3" component="h2" fontWeight={600}>
						{title}
					</Typography>
					<Typography variant="body1">{subTitle}</Typography>
				</Stack>
			</Grid>
		</Grid>
		<Grid item xs={12} pt={4}>
			{children}
		</Grid>
	</Box>
);

HeroSectionCentered.propTypes = {
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
	hasBackground: PropTypes.bool,
	disableMargin: PropTypes.bool,
};

HeroSectionCentered.defaultProps = {
	hasBackground: false,
	disableMargin: false,
	subTitle: '',
};

export default HeroSectionCentered;
