import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'urql';
import { UserDataQuery } from '../querys/userQuery';

export const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({ children }) => {
	const [userData, setUserData] = useState();
	const [result] = useQuery({
		query: UserDataQuery,
		variables: {},
	});
	const { data: user, fetching: fetchingUser } = result;
	useEffect(() => {
		if (!fetchingUser && user) {
			setUserData(user);
		}
	}, [fetchingUser, user]);

	return (
		// eslint-disable-next-line
		<UserContext.Provider value={{ userData, fetchingUser }}>
			{children}
		</UserContext.Provider>
	);
};

UserContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};