import PropTypes from 'prop-types';
import { useUserContext } from '../user.context';
import { windowLocation } from '../../utils/windowLocation';
import PageContainer from '../shared/PageContainer/PageContainer';
import PageSkeleton from '../shared/PageSkeleton';

const ProtectedPage = ({ children }) => {
	const { userData: user, fetchingUser } = useUserContext();
	if (!fetchingUser && user) {
		const isLoggedIn = user.fitnessId > 0;
		if (!isLoggedIn) {
			const encodedUrl = encodeURIComponent(window.location.href);
			windowLocation(`/identity/login?redirect=${encodedUrl}`);
		} else {
			return children;
		}
	}
	return (
		<PageContainer>
			<PageSkeleton />
		</PageContainer>
	);
};

ProtectedPage.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ProtectedPage;
