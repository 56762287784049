import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import { string } from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import { windowLocation } from '../../utils/windowLocation';
import { useUserContext } from '../../components/user.context';

const ErrorPage = ({ errorMessage }) => {
	const navigate = useNavigate();
	// TODO Remove once all of rl is in react
	const { pathname } = useLocation();
	const isRlLink = pathname.includes('resourcelibrary');
	if (isRlLink) windowLocation(pathname);
	const { userData: user = {} } = useUserContext();

	const isLoggedIn = Object.keys(user).length > 0 && user.fitnessId > 0;

	return (
		<Stack
			my={7}
			mx={{ xs: 3, sm: 10, md: 20, lg: 30 }}
			spacing={6}
			alignItems="center"
		>
			{isRlLink ? (
				<CircularProgress color="secondary" />
			) : (
				<>
					<Typography
						textAlign="center"
						color="primary"
						variant="h5"
						component="h1"
					>
						{errorMessage}
					</Typography>
					<Button
						startIcon={<ArrowBackIcon />}
						variant="contained"
						onClick={() => {
							if (isLoggedIn) navigate(`/dashboard`);
							navigate(`/`);
						}}
						color="secondary"
					>
						RETURN HOME
					</Button>
					<Divider sx={{ width: '70%' }} />
					<Typography variant="body1">
						Please{' '}
						<Link
							href="/contactus"
							sx={{ textDecoration: 'none' }}
							color="secondary"
						>
							Contact Us
						</Link>{' '}
						if you have any questions.
					</Typography>
				</>
			)}
		</Stack>
	);
};

ErrorPage.propTypes = {
	/**
	 * Message that will be displayed to the user.
	 */
	errorMessage: string.isRequired,
};

export default ErrorPage;
