import { useState } from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import { useNavigate } from 'react-router-dom';
import { sendGAEvent } from '../../utils/googleAnalytics';
import { windowLocation } from '../../utils/windowLocation';
import { myAccountRoutes } from '../../pages/MyAccount/data';

const UserProfileDropDown = ({ user }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const navigate = useNavigate();
	const navgationSwitcher = ({ path, gaLabel, isNonReactPage }) => {
		sendGAEvent(`click_profileLinks_${gaLabel}`, {
			login_status: 'post_login',
		});
		if (isNonReactPage) {
			windowLocation(path);
		} else {
			navigate(path);
		}
	};

	return (
		<>
			<IconButton
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleOpen}
				aria-label="User profile drop down"
			>
				<PersonSharpIcon />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<MenuItem
					onClick={() => {
						handleClose();
						navgationSwitcher({
							path: '/MyAccount/personal-info',
							isNonReactPage: true,
							gaLabel: 'personal_info',
						});
					}}
				>
					<ListItemIcon sx={{ mr: 2 }}>
						<Avatar sx={{ width: 40, height: 40 }} />
					</ListItemIcon>
					<ListItemText>
						{user.firstName} {user.lastName}
						<br />
						Fitness ID: {user.fitnessId}
					</ListItemText>
				</MenuItem>
				<Divider />
				{myAccountRoutes.map(({ path, gaLabel, key, label, isNonReactPage }) => (
					<MenuItem
						key={key}
						onClick={() => {
							handleClose();
							navgationSwitcher({
								path,
								isNonReactPage,
								gaLabel,
							});
						}}
					>
						{label}
					</MenuItem>
				))}
				<Divider />
				<MenuItem component={Link} onClick={handleClose} href="/logout">
					<ListItemIcon>
						<LogoutIcon />
					</ListItemIcon>
					<ListItemText>Log Out</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
};

UserProfileDropDown.propTypes = {
	user: PropTypes.object,
};

UserProfileDropDown.defaultProps = {
	user: {},
};
export default UserProfileDropDown;
