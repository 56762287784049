import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import UserProfileDropDown from './UserProfileDropDown';
import MobileNavMenu from './MobileNavMenu';
import useIsMobile from '../../utils/customHooks/useIsMobile';
import { ReactComponent as SFLogo } from '../logos/sfLogo.svg';
import { ReactComponent as SFLogoGlyph } from '../logos/sfLogoGlyph.svg';
import { windowLocation } from '../../utils/windowLocation';
import { sendGAEvent } from '../../utils/googleAnalytics';
import { useUserContext } from '../user.context';

const topBarHeight = '80px';
const topBarHeightMobile = '64px';

const LoggedOutActions = ({ isMobile }) => (
	<>
		<Button
			component={Link}
			onClick={() => {
				sendGAEvent('click_preHeaderLinks_login');
				windowLocation('/identity/login');
			}}
		>
			Login
		</Button>
		{!isMobile && (
			<Button
				component={Link}
				sx={{ ml: 2 }}
				variant="contained"
				onClick={() => {
					sendGAEvent('click_preHeaderLinks_registration');
					windowLocation('/identity/registration');
				}}
			>
				Check Eligibility
			</Button>
		)}
	</>
);
LoggedOutActions.propTypes = {
	isMobile: PropTypes.bool.isRequired,
};

const LoggedInActions = ({ user }) => {
	const cartIgnoredCodes = [
		'facilityRemove',
		'cancelAll',
		'cancelAllUser',
		'reactivateAll',
		'reactivateAllUser',
	];

	const storageKey = `fitsubscriptionCart-${user.fitnessId}`;
	const cart = JSON.parse(window.localStorage.getItem(storageKey));

	const cartCount =
		cart?.subscriptionItems
			.filter(i => !cartIgnoredCodes.includes(i.code))
			.map(i => (i.kits ? i.kits.filter(kit => !kit.selected).length : 1))
			.reduce((count, itemCount) => count + itemCount, 0) ?? 0;

	return (
		<>
			<IconButton component={Link} href="/subscription" aria-label="cart">
				<Badge badgeContent={cartCount} color="error">
					<ShoppingCartIcon />
				</Badge>
			</IconButton>
			<UserProfileDropDown user={user} />
		</>
	);
};
LoggedInActions.propTypes = {
	user: PropTypes.object.isRequired,
};

const TopBar = () => {
	const { userData: user = {} } = useUserContext();
	const isMobile = useIsMobile();
	const hasUserData = Object.keys(user).length > 0;

	const { customizationImageRaw: clientLogo, clientName } =
		user?.clientDetails ?? {};

	return (
		<Box sx={{ boxShadow: 1 }}>
			<Container>
				<Toolbar
					disableGutters
					id="topBarWrapper"
					sx={{
						justifyContent: 'space-between',
						minHeight: {
							xs: `${topBarHeightMobile} !important`,
							sm: `${topBarHeight} !important`,
						},
						py: 1,
					}}
				>
					<Stack direction="row" spacing={1} alignItems="center">
						{isMobile && <MobileNavMenu />}
						<Link
							onClick={() => {
								sendGAEvent('click_preHeaderLinks_logo', {
									login_status: user?.fitnessId ? 'post_login' : 'pre_login',
								});
							}}
							href="/"
						>
							{isMobile ? (
								<SFLogoGlyph title="Silver&Fit" style={{ height: '36px' }} />
							) : (
								<SFLogo title="Silver&Fit" style={{ height: '36px' }} />
							)}
						</Link>
						{hasUserData && clientLogo && (
							<>
								<Divider orientation="vertical" flexItem />
								<Box
									component="img"
									src={`data:image/gif;base64,${clientLogo}`}
									alt={clientName || ''}
									sx={{
										maxWidth: { xs: '8rem', sm: '10rem' },
										maxHeight: '36px',
									}}
								/>
							</>
						)}
					</Stack>
					<Box>
						{hasUserData && (
							<>
								{Boolean(!user?.fitnessId) && (
									<LoggedOutActions isMobile={isMobile} />
								)}
								{Boolean(user?.fitnessId) && <LoggedInActions user={user} />}
							</>
						)}
					</Box>
				</Toolbar>
			</Container>
		</Box>
	);
};

export default TopBar;
