import { gql } from 'urql';

export const UserDataQuery = gql`
	query User {
		claims {
			atHomeMembership
			basicFitnessMembership
			canManageEnrollment
			connected
			connectedV2
			digitalWorkouts
			incentivesPlan
			incentivesPlanForWidget
			newsletters
			onlineClasses
			pointsPlan
			resourceLibrary
			socialClubs
			socialEvents
			canAttendVirtualEvents
			visitsPlan
			wellBeing
		}
		clientDetails {
			clientName
			cobrandLogoUrl
			contract
			customizationImageRaw
		}
		hostProperties {
			enableABTestingOptimizeScript
			googleABTestingId
			googleAnalytics4Id
			googleAnalyticsCrossDomainLinks
			googleTagManagerId
		}
		footerConfig {
			copyright
			languageAssistancePath
		}
		firstName
		fitnessId
		groupId
		lastName
		subscriptionStatus
		sessionBlob
		ssId
	}
`;


