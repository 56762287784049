import {
	createBrowserRouter,
	Outlet,
	RouterProvider,
	ScrollRestoration,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import SiteHead from './components/SiteHead';
import useRoutesList from './utils/customHooks/useRoutesList';

const router = routes =>
	createBrowserRouter([
		{
			path: '/',
			element: (
				<>
					<SiteHead />
					<Link
						href="#mainContent"
						sx={{
							mr: '1rem',
							position: 'absolute',
							transform: 'translateX(-200%)',
							transition: 'transform 0.3s',
							'&:focus': {
								position: 'static',
								transform: 'translateX(0)',
							},
						}}
					>
						Skip to Main Content
					</Link>
					<ScrollRestoration />
					<Box display="flex" flexDirection="column" minHeight="100vh">
						<Header>
							<Outlet />
						</Header>
						<Footer />
					</Box>
				</>
			),
			children: routes,
		},
	]);
function App() {
	const routes = useRoutesList();
	return <RouterProvider router={router(routes)} />;
}

export default App;
