import PageSEO from '../../components/shared/PageSEO';
import ErrorPage from './Error';

const ErrorNotFound = () => (
	<>
		<PageSEO pageTitle="Page Not Found" />
		<ErrorPage errorMessage="We couldn't find the page you were looking for." />
	</>
);

export default ErrorNotFound;
