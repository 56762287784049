import PropTypes from 'prop-types';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import { NavLink, useMatch, useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { useTheme } from '@mui/material/styles';
import { sendGAEvent } from '../../utils/googleAnalytics';
import { useUserContext } from '../user.context';
import { windowLocationOpen } from '../../utils/windowLocation';
import useRoutesList from '../../utils/customHooks/useRoutesList';

const navBarHeight = '72px';

const MenuButton = ({ label, path, isExternalLink, gaLabel, fitnessId }) => {
	const { palette } = useTheme();
	const activeLinkStyles = {
		borderRadius: 0,
		borderBottom: `2px solid ${palette.secondary.main}`,
		color: 'secondary.main',
	};
	const defaultLinkStyles = {
		'&:hover': {
			...activeLinkStyles,
		},
		whiteSpace: 'nowrap',
		color: 'text.primary',
		fontWeight: 'bold',
		height: navBarHeight,
		px: { md: 3 },
	};
	const isActive = useMatch(path);

	return (
		<Button
			component={NavLink}
			sx={
				isActive
					? { ...defaultLinkStyles, ...activeLinkStyles }
					: defaultLinkStyles
			}
			size="small"
			onClick={() => {
				sendGAEvent(`click_topNavMenu_${gaLabel}`, {
					login_status: fitnessId ? 'post_login' : 'pre_login',
				});
			}}
			to={path}
		>
			{label}{' '}
			{isExternalLink && (
				<OpenInNew fontSize="small" sx={{ marginLeft: 0.5 }} />
			)}
		</Button>
	);
};
MenuButton.propTypes = {
	label: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	gaLabel: PropTypes.string.isRequired,
	fitnessId: PropTypes.number,
	isExternalLink: PropTypes.bool,
};
MenuButton.defaultProps = {
	isExternalLink: false,
	fitnessId: 0,
};
const NavBar = () => {
	const [menuAnchors, menuAnchorsSet] = useState({});
	const routes = useRoutesList();
	const menuItems = routes.filter(r => r.includeInHeader);

	const menuSet = (key, element) => {
		menuAnchorsSet(state => {
			const newState = { ...state };
			newState[key] = element;
			return newState;
		});
	};
	const navigate = useNavigate();
	const menuNavigate = (path, isExternal) =>
		isExternal ? windowLocationOpen(path) : navigate(path);

	const { userData: user = {}, isFetchingUser } = useUserContext();

	return (
		menuItems?.length && (
			<Box sx={{ bgcolor: 'background.secondary', mt: '2px' }}>
				<Container role="navigation">
					<Toolbar
						disableGutters
						sx={{
							px: '0px !important',
							minHeight: `${navBarHeight} !important`,
							bgcolor: 'background.secondary',
							flexWrap: 'wrap',
							height: 'auto',
						}}
					>
						{!isFetchingUser && Object.keys(user).length > 0 ? (
							menuItems
								.filter(({ isProtected, includeInLoggedInHeader }) =>
									user?.fitnessId
										? isProtected || includeInLoggedInHeader
										: !isProtected
								)
								.filter(({ claim }) => !claim || (user && user.claims[claim]))
								.map(
									({
										label,
										key,
										path,
										children,
										gaLabel,
										isExternalLink,
										hideChildrenFromMenu,
									}) => {
										if (children && !hideChildrenFromMenu) {
											return (
												<div key={key}>
													<Button
														aria-haspopup="true"
														aria-expanded={
															menuAnchors[key] ? 'true' : undefined
														}
														aria-controls={
															menuAnchors[key] ? `${key}-menu` : undefined
														}
														color="inherit"
														id={`${key}-menuButton`}
														onClick={event => menuSet(key, event.currentTarget)}
														sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
														size="small"
													>
														{label}
														<KeyboardArrowDownIcon />
													</Button>
													<Menu
														id={`${key}-menu`}
														anchorEl={menuAnchors[key]}
														onClose={() => menuSet(key, null)}
														open={Boolean(menuAnchors[key])}
														MenuListProps={{
															'aria-labelledby': `${key}-menuButton`,
														}}
													>
														{children
															.filter(
																({ claim }) =>
																	!claim || (user && user.claims[claim])
															)
															.map(s => (
																<MenuItem
																	key={s.key}
																	onClick={() => {
																		menuSet(key, null);
																		sendGAEvent(
																			`click_topNavMenu_${s.gaLabel}`,
																			{
																				login_status: user?.fitnessId
																					? 'post_login'
																					: 'pre_login',
																			}
																		);
																		menuNavigate(s.path, s.isExternalLink);
																	}}
																	sx={{ fontWeight: '500' }}
																>
																	{s.label}{' '}
																	{s.isExternalLink && (
																		<OpenInNew
																			fontSize="small"
																			sx={{ marginLeft: '.5rem' }}
																		/>
																	)}
																</MenuItem>
															))}
													</Menu>
												</div>
											);
										}
										return (
											<MenuButton
												key={key}
												label={label}
												gaLabel={gaLabel}
												isExternalLink={isExternalLink}
												path={path}
												fitnessId={user?.fitnessId}
											/>
										);
									}
								)
						) : (
							<Stack spacing={2} direction="row">
								<Skeleton variant="rectangular" width={150} height={30} />
								<Skeleton variant="rectangular" width={150} height={30} />
								<Skeleton variant="rectangular" width={150} height={30} />
							</Stack>
						)}
					</Toolbar>
				</Container>
			</Box>
		)
	);
};

export default NavBar;
