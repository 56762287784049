import { Outlet } from 'react-router-dom';
import PageContainer from '../../components/shared/PageContainer/PageContainer';
import PageSEO from '../../components/shared/PageSEO';

const HomeKitsRoute = () => (
	<PageContainer>
		<PageSEO pageTitle="Home Kit Selection" />
		<Outlet />
	</PageContainer>
);

export default HomeKitsRoute;
