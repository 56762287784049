import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

const HealthPlansSection = ({ disableMargin }) => (
	<Box
		sx={{
			backgroundColor: 'primary.main',
			py: { xs: 4, sm: 8 },
			mb: disableMargin ? 0 : 4,
		}}
	>
		<Grid container alignItems="center" justifyContent="center" spacing={2}>
			<Grid item xs={11} sm={10} md={8} lg={6}>
				<Stack
					spacing={4}
					alignItems={{ sm: 'center' }}
					sx={{ textAlign: 'center', mx: 2 }}
				>
					<Typography
						variant="h3"
						component="h2"
						color="primary.contrastText"
						fontWeight={600}
					>
						Does your health plan offer the Silver&Fit program?
					</Typography>
					<Typography variant="body1" color="primary.contrastText">
						Silver&amp;Fit program is offered by a variety of Medicare plans. If
						you are looking for a new health plan, or are not sure if your
						current plan offers the Silver&amp;Fit program, you can find a
						participating health plan in your state.
					</Typography>
					<Button
						variant="contained"
						size="large"
						href="/health-plans"
						sx={{ bgcolor: 'primary.contrastText', color: 'primary.main' }}
					>
						Learn More
					</Button>
				</Stack>
			</Grid>
		</Grid>
	</Box>
);
HealthPlansSection.propTypes = {
	disableMargin: PropTypes.bool,
};
HealthPlansSection.defaultProps = {
	disableMargin: false,
};

export default HealthPlansSection;
