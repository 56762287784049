const windowLocation = (url) => {
	window.location.href = url;
};

const windowLocationReplace = (url) => {
	window.location.replace(url)
}

const windowLocationOpen = (url) => {
	window.open(url, '_blank', 'noreferrer');
}

export { windowLocationReplace, windowLocationOpen, windowLocation };
