import fitnessCoachLgavif from './images/fitnessCoach.lg.avif';
import fitnessCoachLgwebp from './images/fitnessCoach.lg.webp';
import fitnessCoachLg2xavif from './images/fitnessCoach.lg.2x.avif';
import fitnessCoachLg2xwebp from './images/fitnessCoach.lg.2x.webp';
import fitnessCoachxs2xavif from './images/fitnessCoach.xs.2x.avif';
import fitnessCoachxs2xwebp from './images/fitnessCoach.xs.2x.webp';
import fitnessCoachxs3xavif from './images/fitnessCoach.xs.3x.avif';
import fitnessCoachxs3xwebp from './images/fitnessCoach.xs.3x.webp';

import wellbeingCoachlgwebp from './images/wellbeingCoach.lg.webp';
import wellbeingCoachlgavif from './images/wellbeingCoach.lg.avif';
import wellbeingCoachlg2xwebp from './images/wellbeingCoach.lg.2x.webp';
import wellbeingCoachlg2xavif from './images/wellbeingCoach.lg.2x.avif';
import wellbeingCoachlg3xwebp from './images/wellbeingCoach.lg.3x.webp';
import wellbeingCoachlg3xavif from './images/wellbeingCoach.lg.3x.avif';
import wellbeingCoachxs2xwebp from './images/wellbeingCoach.xs.2x.webp';
import wellbeingCoachxs2xavif from './images/wellbeingCoach.xs.2x.avif';
import wellbeingCoachxs3xwebp from './images/wellbeingCoach.xs.3x.webp';
import wellbeingCoachxs3xavif from './images/wellbeingCoach.xs.3x.avif';

import wellbeinglgwebp from './images/wellbeing.lg.webp';
import wellbeinglgavif from './images/wellbeing.lg.avif';
import wellbeinglg2xwebp from './images/wellbeing.lg.2x.webp';
import wellbeinglg2xavif from './images/wellbeing.lg.2x.avif';
import wellbeinglg3xwebp from './images/wellbeing.lg.3x.webp';
import wellbeinglg3xavif from './images/wellbeing.lg.3x.avif';
import wellbeingxs2xwebp from './images/wellbeing.xs.2x.webp';
import wellbeingxs2xavif from './images/wellbeing.xs.2x.avif';
import wellbeingxs3xwebp from './images/wellbeing.xs.3x.webp';
import wellbeingxs3xavif from './images/wellbeing.xs.3x.avif';

import herolgwebp from './images/hero.lg.webp';
import herolgavif from './images/hero.lg.avif';
import herolg2xwebp from './images/hero.lg.2x.webp';
import herolg2xavif from './images/hero.lg.2x.avif';
import herolg3xwebp from './images/hero.lg.3x.webp';
import herolg3xavif from './images/hero.lg.3x.avif';
import heroxs2xwebp from './images/hero.xs.2x.webp';
import heroxs2xavif from './images/hero.xs.2x.avif';
import heroxs3xwebp from './images/hero.xs.3x.webp';
import heroxs3xavif from './images/hero.xs.3x.avif';

import getFitAtHomelgwebp from './images/getFitAtHome.lg.webp';
import getFitAtHomelgavif from './images/getFitAtHome.lg.avif';
import getFitAtHomelg2xwebp from './images/getFitAtHome.lg.2x.webp';
import getFitAtHomelg2xavif from './images/getFitAtHome.lg.2x.avif';
import getFitAtHomelg3xwebp from './images/getFitAtHome.lg.3x.webp';
import getFitAtHomelg3xavif from './images/getFitAtHome.lg.3x.avif';

import testimoniallgwebp from './images/testimonial.lg.webp';
import testimoniallgavif from './images/testimonial.lg.avif';
import testimoniallg2xwebp from './images/testimonial.lg.2x.webp';
import testimoniallg2xavif from './images/testimonial.lg.2x.avif';
import testimoniallg3xwebp from './images/testimonial.lg.3x.webp';
import testimoniallg3xavif from './images/testimonial.lg.3x.avif';
import testimonialxs2xwebp from './images/testimonial.xs.2x.webp';
import testimonialxs2xavif from './images/testimonial.xs.2x.avif';
import testimonialxs3xwebp from './images/testimonial.xs.3x.webp';
import testimonialxs3xavif from './images/testimonial.xs.3x.avif';



export const fitnessCoachBg = [
	fitnessCoachLgavif,
	fitnessCoachLgwebp,
	fitnessCoachLg2xavif,
	fitnessCoachLg2xwebp,
	fitnessCoachxs2xavif,
	fitnessCoachxs2xwebp,
	fitnessCoachxs3xavif,
	fitnessCoachxs3xwebp,
];

export const heroBg = [
	herolgwebp,
	herolgavif,
	herolg2xwebp,
	herolg2xavif,
	herolg3xwebp,
	herolg3xavif,
	heroxs2xwebp,
	heroxs2xavif,
	heroxs3xwebp,
	heroxs3xavif,
];

export const getFitAtHomeBg = [
	getFitAtHomelgwebp,
	getFitAtHomelgavif,
	getFitAtHomelg2xwebp,
	getFitAtHomelg2xavif,
	getFitAtHomelg3xwebp,
	getFitAtHomelg3xavif,
];

export const wellbeingCoachBg = [
	wellbeingCoachlgwebp,
	wellbeingCoachlgavif,
	wellbeingCoachlg2xwebp,
	wellbeingCoachlg2xavif,
	wellbeingCoachlg3xwebp,
	wellbeingCoachlg3xavif,
	wellbeingCoachxs2xwebp,
	wellbeingCoachxs2xavif,
	wellbeingCoachxs3xwebp,
	wellbeingCoachxs3xavif,
];
export const wellbeingBg = [
	wellbeinglgwebp,
	wellbeinglgavif,
	wellbeinglg2xwebp,
	wellbeinglg2xavif,
	wellbeinglg3xwebp,
	wellbeinglg3xavif,
	wellbeingxs2xwebp,
	wellbeingxs2xavif,
	wellbeingxs3xwebp,
	wellbeingxs3xavif,
];
export const testimonialBg = [
	testimoniallgwebp,
	testimoniallgavif,
	testimoniallg2xwebp,
	testimoniallg2xavif,
	testimoniallg3xwebp,
	testimoniallg3xavif,
	testimonialxs2xwebp,
	testimonialxs2xavif,
	testimonialxs3xwebp,
	testimonialxs3xavif,
];
