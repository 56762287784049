import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import PageContainer from './PageContainer/PageContainer';

export const SkeletonTitle = () => (
	<Skeleton sx={{ mt: 2 }} height={80} width="25%" />
);

export const SkeletonTextBlock = () => (
	<Skeleton sx={{ mt: 2 }} variant="rectangular" width="100%" height={600} />
);

export const SkeletonLink = () => (
	<Typography variant="body1" gutterBottom>
		<Skeleton width="25%"/>
	</Typography>
);

const PageSkeleton = () => (
	<PageContainer>
		<SkeletonTitle />
		<SkeletonTextBlock />
	</PageContainer>
);

export default PageSkeleton;
