import { useQuery as reactQuery } from 'react-query';
import { generateHomeKitRoutes } from '@ashn/home-kit-react/HomeKit';
import { fetchFitsubscriptionManagamentData } from '../../data/apiCalls';
import { useUserContext } from '../../components/user.context';
import getEnvironment from '../helpers/getEnvironment';
import { windowLocation } from '../windowLocation';
import ServerErrorPage from '../../pages/Errors/ServerErrorPage';
/**
 *
 * Had to create a hook to generate the home kits routes so data could be fetched
 * from the fitsub api necessary to render the homekits
 * @returns Array of home kits child routes, or an error route if the fitsub api fails
 */
const useHomeKitRoutes = () => {
	const { userData: { ssId, fitnessId, sessionBlob } = {} } = useUserContext();

	const { data = { program: {} } } = reactQuery(
		['fitsubData', sessionBlob, fitnessId],
		fetchFitsubscriptionManagamentData,
		{
			enabled: !!fitnessId,
		}
	);
	// check if there is a fitsub error
	// returning null so error does not cause the whole app to fail
	if (data.name === 'AxiosError')
		return [
			{
				index: true,
				element: <ServerErrorPage />,
			},
		];

	const { program: { homeKitsFee, endOfBenefitYear } = {} } = data;

	// get fitsubscription cart object
	const storageKey = `fitsubscriptionCart-${fitnessId}`;
	const cart = JSON.parse(window.localStorage.getItem(storageKey));
	// find the currently selected kits in the cart
	const selectedKitsCart =
		cart?.subscriptionItems
			.filter(i => i.code === 'kitsAdd' && i.kits)
			.flatMap(i => i.kits)
			.filter(k => !k.selected)
			.map(k => k.code) ?? [];

	if (ssId && endOfBenefitYear) {
		const homeKitData = {
			ssId,
			apiEnv: getEnvironment(),
			benefitYearEndDate: endOfBenefitYear,
			kitPrice: homeKitsFee,
			selectedKitsCart,
			onNextStep: selectedKits => {
				window.sessionStorage.setItem(
					`selectedKits-${fitnessId}`,
					JSON.stringify(selectedKits)
				);
				windowLocation('/kitSelection');
			},
			hasOwnProvider: true,
		};
		return generateHomeKitRoutes(homeKitData);
	}
	return null;
};
export default useHomeKitRoutes;
