import { ReactComponent as Pin } from '../logos/pinLogo.svg';
import { ReactComponent as Fb } from '../logos/fbLogo.svg';
import { ReactComponent as Twitter } from '../logos/twitterLogo.svg';
import { ReactComponent as Li } from '../logos/linkedInLogo.svg';
import { ReactComponent as Yt } from '../logos/ytLogo.svg';

export const pdfPath = '/language.pdf';

export const copyrightSubText = 'Last Updated 01/01/2023';

export const languageAssistance = [
	{
		language: 'English',
		// empty string since additional text for screen readers is not needed since it is english
		srText: '',
	},
	{
		language: 'Español',
		srText: 'Spanish',
	},
	{
		language: '繁體中文',
		srText: 'Chinese',
	},
	{
		language: 'Tagalog',
		srText: '',
	},
	{
		language: 'Tiếng Việt',
		srText: 'Vietnamese',
	},
	{
		language: 'العربية',
		srText: 'Arabic',
	},
	{
		language: 'Français',
		srText: 'French',
	},
	{
		language: '한국어',
		srText: 'Korean',
	},
	{
		language: 'Русский',
		srText: 'Russian',
	},
	{
		language: 'Deutsch',
		srText: 'German',
	},
	{
		language: 'Kreyòl Ayisyen',
		srText: 'French Creole',
	},
	{
		language: 'हिंदी',
		srText: 'Hindi',
	},
	{
		language: 'Português',
		srText: 'Portuguese',
	},
	{
		language: 'Italiano',
		srText: 'Italian',
	},
	{
		language: 'Polski',
		srText: 'Polish',
	},
	{
		language: 'اُردُو',
		srText: 'Farsi',
	},
	{
		language: 'אידיש',
		srText: 'Yiddish',
	},
	{
		language: 'বাংলা',
		srText: 'Bengali',
	},
	{
		language: 'Ελληνικά',
		srText: 'Greek',
	},
	{
		language: 'Shqiptar',
		srText: 'Albanian',
	},
];

export const socialLinks = [
	{
		name: 'facebook',
		alt: 'Facebook Logo',
		src: props => <Fb {...props} />,
		link: 'https://www.facebook.com/SilverandFit/',
	},
	{
		name: 'twitter',
		alt: 'Twitter Logo',
		src: props => <Twitter {...props} />,
		link: 'https://twitter.com/SilverandFit',
	},
	{
		name: 'linkedin',
		alt: 'LinkedIn Logo',
		src: props => <Li {...props} />,
		link: 'https://www.linkedin.com/company/american-specialty-health',
	},
	{
		name: 'youtube',
		alt: 'YouTube Logo',
		src: props => <Yt {...props} />,
		link: 'https://www.youtube.com/user/SilverandFit',
	},
	{
		name: 'pinterest',
		alt: 'Pinterest Logo',
		src: props => <Pin {...props} />,
		link: 'https://pinterest.com/silverandfit',
	},
];
