import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const IconCard = ({ title, icon, content, children }) => (
	<Card
		raised={false}
		elevation={0}
		sx={{ backgroundColor: 'primary.light', flex: 1 }}
	>
		<CardHeader
			avatar={<Avatar sx={{ bgcolor: 'primary.main' }}>{icon}</Avatar>}
			title=<Typography fontSize={24} fontWeight={600}>
				{' '}
				{title}
			</Typography>
		/>
		<CardContent>
			<Typography variant="body1">{content}</Typography>
			{children}
		</CardContent>
	</Card>
);

IconCard.propTypes = {
	title: PropTypes.string.isRequired,
	icon: PropTypes.element.isRequired,
	content: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
IconCard.defaultProps = {
	children: [],
	content: '',
};
export default IconCard;
