import SupportAgent from '@mui/icons-material/SupportAgent';
import Groups from '@mui/icons-material/Groups';
import Computer from '@mui/icons-material/Computer';
import CorporateFare from '@mui/icons-material/CorporateFare';
import CalendarToday from '@mui/icons-material/CalendarToday';
import HomeIcon from '@mui/icons-material/Home';
import WineBarOutlined from '@mui/icons-material/WineBarOutlined';
import DirectionsRunOutlined from '@mui/icons-material/DirectionsRunOutlined';

import { NavLink } from 'react-router-dom';
import Link from '@mui/material/Link';

export const features = ({ totalCount = 0, disable2024Content }) =>
	[
		{
			title: 'FitnessCoach',
			icon: <SupportAgent />,
			content: () => (
				<>
					Work with a certified personal fitness trainer, via live virtual
					training sessions, to set and achieve your fitness goals.
				</>
			),
		},
		{
			title: 'Well-Being Club',
			icon: <Groups />,
			content: () => (
				<>
					Connect with others, view exclusive articles and videos, and join
					live-streaming virtual events.
				</>
			),
		},
		{
			title: 'On-Demand Workout Videos',
			icon: <Computer />,
			content: () => (
				<>
					Access a wide variety of workout videos at your preferred fitness
					level, available anytime.
				</>
			),
		},
		{
			title: 'Fitness Center Access',
			icon: <CorporateFare />,
			content: () => (
				<>
					Work out at one of our {totalCount > 0 ? `${totalCount}+` : ''}{' '}
					participating fitness centers or studios.
				</>
			),
		},
		{
			title: 'Workout Plans',
			icon: <CalendarToday />,
			content: () => (
				<>
					Start a personalized 14-day Workout Plan that is designed to help you
					begin a goal-based fitness routine.
				</>
			),
		},
		{
			title: 'Home Fitness Kits',
			icon: <HomeIcon />,
			content: () => (
				<>
					Exercise in the comfort of home by choosing one kit per benefit year.
				</>
			),
		},
		{
			title: disable2024Content
				? 'Healthy Aging Coaching'
				: 'Well-Being Coaching',
			icon: <SupportAgent />,
			content: disable2024Content
				? () => (
						<>
							Connect with a personal health coach who can help you achieve
							various health goals during scheduled phone sessions.
						</>
				  )
				: () => (
						<>
							Connect with a Well-Being Coach to help you achieve your health
							goals during scheduled phone or video sessions.
						</>
				  ),
		},
		{
			title: disable2024Content
				? 'Silver&Fit Connected!™ Tool'
				: 'Exercise Tracking',
			icon: <DirectionsRunOutlined />,
			content: disable2024Content
				? () => (
						<>
							Sync your favorite wearable fitness tracker or mobile app to the
							Connected! tool to track your exercise.
						</>
				  )
				: () => (
						<>
							Sync your favorite wearable fitness tracker to the Silver&amp;Fit
							Connected!&trade; tool or ASHSync&trade; Mobile App to track your
							exercise.
						</>
				  ),
		},
		{
			title: 'Rewards',
			icon: <WineBarOutlined />,
			content: () => (
				<>
					Earn points and receive collectible hats and pins by tracking your
					activity.
				</>
			),
		},
		// Remove filter afer 1/1
	].filter(f => (disable2024Content ? f.title !== 'FitnessCoach' : f));

export const fitAtHomeBullets = [
	{
		header: 'Workout Plans',
		content: () => (
			<>
				Members can receive a tailored 14-day Workout Plan based on their
				fitness goals.
			</>
		),
	},
	{
		header: 'On-Demand Workout Videos',
		content: () => (
			<>
				Members can access workout videos including Cardio, Strength, Dance,
				Yoga, Pilates, Meditation, Cycling, and more.
			</>
		),
	},
	{
		header: 'Home Fitness Kits',
		content: () => (
			<>
				Members can exercise in the comfort and safety of home by choosing one
				kit per benefit year.{' '}
				<Link color="secondary" component={NavLink} to="/home-kits">
					View Kits.
				</Link>
			</>
		),
	},
	{
		header: 'Community Engagement',
		content: () => (
			<>
				Members and non-members are invited to join us on{' '}
				<Link color="secondary" href="https://www.facebook.com/SilverandFit/">
					Facebook Live
				</Link>{' '}
				and{' '}
				<Link
					color="secondary"
					href="https://www.youtube.com/user/silverandfit"
				>
					YouTube
				</Link>{' '}
				for various types and levels of workouts that are open to the public.
				Try cardio, yoga, strength training, and more. Invite your friends to
				join online, too!{' '}
				<Link color="secondary" component={NavLink} to="/workouts">
					View class schedule.
				</Link>
			</>
		),
	},
];

export const steps = [
	{
		header: 'Try It Out',
		body: 'Join our community in free workout classes on Facebook Live and YouTube.',
	},
	{
		header: 'Check Eligibility',
		body: 'Check your eligibility and, if eligible, create your account to see what features your health plan offers.',
	},
	{
		header: 'Get Moving, Together',
		body: 'Choose to work out at home or at a fitness center with the flexibility to find what motivates you, featuring fresh content daily.',
	},
];
