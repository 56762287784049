import { Helmet } from 'react-helmet-async';
import { string } from 'prop-types';

const PageSEO = ({ pageTitle, description }) => (
	<Helmet>
		<title>{pageTitle} | Silver&amp;Fit</title>
		{description && <meta name="description" content={description} />}
	</Helmet>
);

PageSEO.propTypes = {
	pageTitle: string.isRequired,
	description: string,
};
PageSEO.defaultProps = {
	description: null,
};
export default PageSEO;
