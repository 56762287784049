import { Helmet } from 'react-helmet-async';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { useUserContext } from './user.context';

const SiteHead = () => {
	const { userData: user, fetchingUser } = useUserContext();
	const hostProps = user?.hostProperties ?? {};

	return (
		hostProps.googleTagManagerId &&
		!fetchingUser && (
			<GTMProvider
				state={{
					id: hostProps.googleTagManagerId,
				}}
			>
				<Helmet>
					<script
						type="text/javascript"
						async
						src={`https://www.googletagmanager.com/gtag/js?id=${hostProps.googleAnalytics4Id}&amp;l=dataLayer&amp;cx=c`}
					/>
					<script type="text/javascript">
						{`
							window.dataLayer = window.dataLayer || [];
							function gtag(){dataLayer.push(arguments);}
							gtag('js', new Date());
							gtag('set', {'groupId':'${user.groupId}', 'subscriptionStatus':'${user.subscriptionStatus}'})
							gtag('config', '${hostProps.googleAnalytics4Id}', 
							{
									'groupId': '${user.groupId}',
									'subscriptionStatus': '${user.subscriptionStatus}'
								}
							);
						`}
					</script>
				</Helmet>
			</GTMProvider>
		)
	);
};

export default SiteHead;
